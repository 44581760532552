<template>
  <div class="not-found-page">
    <nav class="breadcrumb-show d-none d-md-block">
      <div class="container">
        <p class="breadcrumb-show__description">
          404 Not Found
        </p>
      </div>
    </nav>
    <section class="content-wrapper">
      <div class="container">
        <div class="error-details">
          Sorry, an error has occured, Requested page not found!
        </div>
        <div class="error-actions">
          <router-link :to="{ path: '/'}">
            Go back
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'NotFoundPage',
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped></style>
